<template>
    <a-modal
            style="bottom: 20px;"
            title="Nouveau message"
            :visible="visible"
            @cancel="handleCancel"
            :width="800"
            :destroyOnClose="true"
            :okButtonProps="{ props: {htmlType : 'submit'} }"
    >
        <template slot="footer">
            <a-button key="submit" type="primary" :loading="processing" @click="handleOk">Envoyer</a-button>
        </template>
        <a-form
                :layout="formLayout"
                id="add_attestation_form"
                name="add_attestation_form"
                class="adherent_form"
                :form="form"
        >
            <a-row>
                <a-form-item v-bind="formItemLayout">
                    <a-select
                            v-if="isAdmin"
                            mode="multiple"
                            :disabled="isDestinationsSelectDisabled"
                            style="width: 100%"
                            @change="handleChange"
                            placeholder="Destinataires"
                            v-decorator="[
                                                'to',
                                                {rules: [{
                                               required: checkDestinataires, message: 'Destinataires est obligatoire!'
                                             }]}
                                              ]"
                    >
                        <a-select-option v-for="email in emails"   :key="email.email">
                            {{email.email}}
                        </a-select-option>
                    </a-select>
                    <a-input v-else placeholder="A SOR" disabled=""/>
                </a-form-item>
                <a-form-item v-bind="formItemLayout">
                    <a-select
                            v-if="isAdmin"
                            mode="multiple"
                            :disabled="isDestinationsSelectDisabled"
                            style="width: 100%"
                            @change="handleWilayaFilterChange"
                            placeholder="Wilayas"
                            v-decorator="[
                                                'wilayas',
                                                {rules: [{
                                               required: checkWilayas, message: 'Wilayas est obligatoire!'
                                             }]}
                                              ]"
                    >
                        <a-select-option v-for="wilaya in wilayas" :key="wilaya.id"
                         :value="wilaya.id">
                            {{wilaya.nom_fr}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item v-bind="formItemLayout">
                    <a-input
                            max_length="254"
                            placeholder="Objet"
                            v-decorator="[
                                           'subject',
                                           {
                                             rules: [{
                                               required: true, message: 'Objet est obligatoire!',
                                             }]
                                           }
                                         ]"
                    />
                </a-form-item>

                <a-form-item v-bind="formItemLayout">
                    <a-textarea
                            placeholder="Votre message"
                            :rows="13"
                            v-decorator="[
                                           'message',
                                           {
                                             rules: [{
                                               required: true, message: 'Le   message est obligatoire!',
                                             }]
                                           }
                                         ]"
                    >
                    </a-textarea>
                </a-form-item>
                <a-form-item v-bind="formItemLayout">
                    <a-upload
                            name="file"
                            
                            :action="uploadUrl()"
                            :headers="{Authorization: `Bearer ${getAuthorizationToken()}`}"
                            @change="handleUploadChange($event)"
                            :remove="deleting"

                    >
                        <a-button class="ant-btn-primary">
                            <a-icon type="link"/>
                            Fichiers attaché
                        </a-button>
                    </a-upload>
                </a-form-item>
            </a-row>
        </a-form>
    </a-modal>
</template>
<script>
    import {mapState} from "vuex";
 
    export default {
        name: "NewMail",
        mounted() {
   this.fillTheForm();
        },
        data() {
            return {
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 24}
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 24}
                    }
                },
                tailFormItemLayout: {
                    wrapperCol: {
                        xs: {
                            span: 22,
                            offset: 0
                        },
                        sm: {
                            span: 22,
                            offset: 20
                        }
                    }
                },
            form : this.$form.createForm(this),
                checkDestinataires : true,
                checkWilayas : false,
            };
        },
        props: ["visible", "isAdmin", "processing", "replyTo", "isDestinationsSelectDisabled"],
        computed: {
            ...mapState({
                emails: state => state.messageries.users_emails,
                wilayas: state => state.wilayas.organisation_wilayas,
                base_url: state => state.auth.base_url,
            })
        },
        methods: {
            fillTheForm(){
                if (this.replyTo) {
                    this.$nextTick(() => {
                        this.form.setFieldsValue({to: this.replyTo});
                });
            }
            },
            uploadUrl() {
                return `${this.base_url}/email/file`;
            },
                  deleting(info) {
                const ID = info.response.id;
                this.$emit("remove", ID);
            },
            handleUploadChange(info) {
                if (info.file.status === "removed") {
                    this.$emit("remove_success");
                }
                if (info.file.status === "done") {
                    this.$_showSuccessMessage(
                        `${info.file.name} a été importé avec succès`
                    );
                    this.$emit("upload_success", info.fileList[info.fileList.length - 1].response.id);
                } else if (info.file.status === "error") {
                    this.$_throwAnError(`Echec de l'importation.`);
                }
            },
            close() {
                this.$emit("close");
            },
            handleCancel() {
                this.$emit("cancel");
            },
            handleOk() {
                this.$emit("create");
            },
            handleChange(value, option) {
                this.checkWilayas =  !value ;
                this.$nextTick(() => {
                    this.form.validateFields(['wilayas'], { force: true });
                });
            },
            handleWilayaFilterChange(value) {
                this.checkDestinataires =  !value ;
                this.$nextTick(() => {
                    this.form.validateFields(['to'], { force: true });
                });
            }
        }
    };
</script>
